import React, { useRef, useState } from "react";
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
    FilterFn,
} from "@tanstack/react-table";
import { useQuery } from "react-query";
import { formataTexto } from "../../helpers";
import { DTableData } from "../../types";
import { TableBar } from "./tableBar";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { fuzzyFilter } from "./clientResumeTable";

interface IModalClient {
    isOpen: boolean;
    setIsOpen: any;
    client: any;
}

export default function ModalClient(props: IModalClient) {
    const ClientData = useQuery<any>(props.client);
    const cardTitle = Object.keys(ClientData.data.cards);
    const [property_data] = useState<any[]>(ClientData.data.property_table);
    const [debt_data] = useState<any[]>(ClientData.data.debt_table);
    const [data] = useState<any[]>(ClientData.data.table);
    const [sorting, setSorting] = useState<SortingState>([]);
    const [debt_sorting, setDebt_Sorting] = useState<SortingState>([]);
    const [property_sorting, setProperty_Sorting] = useState<SortingState>([]);
    const columnHelper = createColumnHelper<DTableData>();
    const debt_columnHeaders = ["Banco", "Tipo", "Mês", "Ano", "Valor"];
    const property_columnHeaders = ["Tipo", "Valor"];
    const columnHeaders = [
        "Cultura",
        "Ha",
        "Produtividade/Ha",
        "Preço de Venda",
        "Proprietário",
        "CPF",
        "IE",
        "Endereço",
        "Custo Aplicação",
        "Custo Serviço",
    ];
    const property_columns: any = [];
    const debt_columns: any = [];
    const columns: any = [];

    property_columnHeaders.forEach(element => {
        if (element === "Valor") {
            property_columns.push(
                columnHelper.accessor(element, {
                    header: element,
                    cell: props => (
                        <label>
                            {"R$ " +
                                Number(
                                    Number(props.getValue()).toFixed(1)
                                ).toLocaleString(undefined, {
                                    minimumFractionDigits: 1,
                                })}
                        </label>
                    ),

                    footer: "",
                })
            );
            return;
        }
        property_columns.push(
            columnHelper.accessor(element, {
                header: element,
                footer: "",
            })
        );
    });

    debt_columnHeaders.forEach(element => {
        if (element === "Valor") {
            debt_columns.push(
                columnHelper.accessor(element, {
                    header: element,
                    cell: props => (
                        <label>
                            {"R$ " +
                                Number(
                                    Number(props.getValue()).toFixed(1)
                                ).toLocaleString(undefined, {
                                    minimumFractionDigits: 1,
                                })}
                        </label>
                    ),

                    footer: "",
                })
            );
            return;
        }
        debt_columns.push(
            columnHelper.accessor(element, {
                header: element,
                footer: "",
            })
        );
    });

    columnHeaders.forEach(element => {
        if (element === "Cultura") {
            columns.push(
                columnHelper.accessor(element, {
                    header: element,
                    cell: props => {
                        return formataTexto(props.getValue().toString());
                    },
                })
            );
            return;
        }
        if (element === "Preço de Venda") {
            columns.push(
                columnHelper.accessor(element, {
                    header: element,
                    cell: props => (
                        <label>
                            {"R$ " +
                                Number(
                                    Number(props.getValue()).toFixed(1)
                                ).toLocaleString(undefined, {
                                    minimumFractionDigits: 1,
                                })}
                        </label>
                    ),

                    footer: "",
                })
            );
            return;
        }
        if (element === "Ha") {
            columns.push(
                columnHelper.accessor(element, {
                    header: element,
                    cell: props =>
                        Number(
                            Number(props.getValue()).toFixed(1)
                        ).toLocaleString(undefined, {
                            minimumFractionDigits: 1,
                        }) + " ha",
                    footer: "",
                })
            );
            return;
        }
        if (element === "Produtividade/Ha") {
            columns.push(
                columnHelper.accessor(element, {
                    header: element,
                    cell: props =>
                        props.getValue().toLocaleString(undefined, {
                            minimumFractionDigits: 1,
                        }),
                    footer: "",
                })
            );
            return;
        }

        columns.push(
            columnHelper.accessor(element, {
                header: element,
                footer: "",
            })
        );
    });

    const property_table = useReactTable({
        data: property_data,
        columns: property_columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        state: {
            sorting: property_sorting,
        },
        onSortingChange: setProperty_Sorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });
    const debt_table = useReactTable({
        data: debt_data,
        columns: debt_columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        state: {
            sorting: debt_sorting,
        },
        onSortingChange: setDebt_Sorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });
    const table = useReactTable({
        data,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        state: {
            sorting,
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    const debt_ref = useRef(null);
    const property_ref = useRef(null);
    const table_ref = useRef(null);

    return (
        <>
            {props.isOpen && ClientData.data && (
                <>
                    {ClientData.data && (
                        <div className="modal modal-open ">
                            <div className="modal-box bg-zinc-900 min-w-[98%] min-h-[98%]  m-0 p-0 overflow-hidden flex flex-col">
                                <label className="text-white text-left bg-slate-700 text-3xl p-4">
                                    Dashboard {props.client}
                                </label>
                                <label
                                    onClick={() => {
                                        props.setIsOpen(false);

                                        const page =
                                            document.getElementById("page");
                                        if (page) page.style.overflow = "auto";
                                    }}
                                    className="btn btn-sm btn-circle absolute right-2 top-2"
                                >
                                    ✕
                                </label>
                                <div className=" h-fit text-white overflow-scroll">
                                    <div className="h-fit bg-zinc-900 text-left">
                                        {ClientData.data.chart && (
                                            <TableBar
                                                data={ClientData.data.chart}
                                                title={"Culturas por Hectares"}
                                                h="25px"
                                            />
                                        )}
                                    </div>
                                    <div className="grid grid-flow-col p-4 h-full gap-4 overflow-x-auto scroll">
                                        {ClientData.data.cards && (
                                            <>
                                                {cardTitle.map(
                                                    (title: string) => (
                                                        <div
                                                            className="card whitespace-nowrap w-full bg-slate-800 shadow-xl border text-white  "
                                                            key={title}
                                                        >
                                                            <div className="card-body text-center ">
                                                                <h2 className="text-lg whitespace-nowrap">
                                                                    {title}
                                                                </h2>
                                                                <label className="text-4xl font-extrabold">
                                                                    {title ===
                                                                    "Ha Plantio"
                                                                        ? Number(
                                                                              Number(
                                                                                  ClientData
                                                                                      .data
                                                                                      .cards[
                                                                                      title
                                                                                  ]
                                                                              ).toFixed(
                                                                                  1
                                                                              )
                                                                          ).toLocaleString(
                                                                              undefined,
                                                                              {
                                                                                  minimumFractionDigits: 1,
                                                                              }
                                                                          ) +
                                                                          " ha"
                                                                        : "R$ " +
                                                                          Number(
                                                                              (
                                                                                  ClientData
                                                                                      .data
                                                                                      .cards[
                                                                                      title
                                                                                  ] /
                                                                                  1000000
                                                                              ).toFixed(
                                                                                  1
                                                                              )
                                                                          ).toLocaleString(
                                                                              undefined,
                                                                              {
                                                                                  minimumFractionDigits: 1,
                                                                              }
                                                                          ) +
                                                                          " M"}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                            </>
                                        )}
                                    </div>

                                    <div className="p-4 pt-0 mt-10 pb-0">
                                        <div> Tabela de Patrimõnio</div>
                                        <div>
                                            <DownloadTableExcel
                                                filename={`Tabela Patrimõnio ${
                                                    props.client
                                                } ${Date()}`}
                                                sheet="Tabela Patrimõnio"
                                                currentTableRef={
                                                    property_ref.current
                                                }
                                            >
                                                <button className="btn">
                                                    Exportar para excel
                                                </button>
                                            </DownloadTableExcel>
                                        </div>
                                    </div>
                                    <div className="p-4 pt-0 rounded-xl rounded-t-none max-h-[calc(75vh)] overflow-auto scroll ">
                                        {ClientData.data.property_table && (
                                            <>
                                                <table
                                                    className=" table-normal min-w-full bg-slate-700 text-center border-separate border overflow-scroll"
                                                    cellSpacing={0}
                                                    ref={property_ref}
                                                >
                                                    <thead className=" bg-slate-800 sticky top-0 z-10 ">
                                                        {property_table
                                                            .getHeaderGroups()
                                                            .map(
                                                                headerGroup => (
                                                                    <tr
                                                                        key={
                                                                            headerGroup.id
                                                                        }
                                                                    >
                                                                        {headerGroup.headers.map(
                                                                            header => {
                                                                                return (
                                                                                    <th
                                                                                        {...{
                                                                                            className:
                                                                                                header.column.getCanSort()
                                                                                                    ? "cursor-pointer select-none border-t border-b hover:bg-slate-900"
                                                                                                    : "",
                                                                                            onClick:
                                                                                                header.column.getToggleSortingHandler(),
                                                                                        }}
                                                                                        key={
                                                                                            header.id
                                                                                        }
                                                                                        colSpan={
                                                                                            header.colSpan
                                                                                        }
                                                                                    >
                                                                                        {header.isPlaceholder ? null : (
                                                                                            <div>
                                                                                                {flexRender(
                                                                                                    header
                                                                                                        .column
                                                                                                        .columnDef
                                                                                                        .header,
                                                                                                    header.getContext()
                                                                                                )}
                                                                                                {{
                                                                                                    asc: " ⬆ ",
                                                                                                    desc: " ⬇",
                                                                                                }[
                                                                                                    header.column.getIsSorted() as string
                                                                                                ] ??
                                                                                                    null}
                                                                                            </div>
                                                                                        )}
                                                                                    </th>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </tr>
                                                                )
                                                            )}
                                                    </thead>
                                                    <tbody>
                                                        {property_table
                                                            .getRowModel()
                                                            .rows.map(row => (
                                                                <tr
                                                                    key={row.id}
                                                                    className="hover:bg-slate-600"
                                                                >
                                                                    {row
                                                                        .getVisibleCells()
                                                                        .map(
                                                                            cell => {
                                                                                return (
                                                                                    <td
                                                                                        key={
                                                                                            cell.id
                                                                                        }
                                                                                        className=" border-b border-t "
                                                                                    >
                                                                                        {flexRender(
                                                                                            cell
                                                                                                .column
                                                                                                .columnDef
                                                                                                .cell,
                                                                                            cell.getContext()
                                                                                        )}
                                                                                    </td>
                                                                                );
                                                                            }
                                                                        )}
                                                                </tr>
                                                            ))}
                                                    </tbody>
                                                    <tfoot>
                                                        {property_table
                                                            .getFooterGroups()
                                                            .map(
                                                                footerGroup => (
                                                                    <tr
                                                                        key={
                                                                            footerGroup.id
                                                                        }
                                                                        className="border "
                                                                    >
                                                                        {footerGroup.headers.map(
                                                                            header => {
                                                                                return (
                                                                                    <th
                                                                                        key={
                                                                                            header.id
                                                                                        }
                                                                                    >
                                                                                        {flexRender(
                                                                                            header
                                                                                                .column
                                                                                                .columnDef
                                                                                                .footer,
                                                                                            header.getContext()
                                                                                        )}
                                                                                    </th>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </tr>
                                                                )
                                                            )}
                                                    </tfoot>
                                                </table>
                                            </>
                                        )}
                                    </div>

                                    <div className="p-4 pt-0 mt-10 pb-0">
                                        <div> Tabela de Endividamento</div>
                                        <div>
                                            <DownloadTableExcel
                                                filename={`Tabela Endividamento ${
                                                    props.client
                                                } ${Date()}`}
                                                sheet="Tabela Endividamento"
                                                currentTableRef={
                                                    debt_ref.current
                                                }
                                            >
                                                <button className="btn">
                                                    Exportar para excel
                                                </button>
                                            </DownloadTableExcel>
                                        </div>
                                    </div>
                                    <div className="p-4 pt-0 rounded-xl rounded-t-none max-h-[calc(75vh)] overflow-auto scroll ">
                                        {ClientData.data.debt_table && (
                                            <>
                                                <table
                                                    ref={debt_ref}
                                                    className=" table-normal min-w-full bg-slate-700 text-center border-separate border overflow-scroll"
                                                    cellSpacing={0}
                                                >
                                                    <thead className=" bg-slate-800 sticky top-0 z-10 ">
                                                        {debt_table
                                                            .getHeaderGroups()
                                                            .map(
                                                                headerGroup => (
                                                                    <tr
                                                                        key={
                                                                            headerGroup.id
                                                                        }
                                                                    >
                                                                        {headerGroup.headers.map(
                                                                            header => {
                                                                                return (
                                                                                    <th
                                                                                        {...{
                                                                                            className:
                                                                                                header.column.getCanSort()
                                                                                                    ? "cursor-pointer select-none border-t border-b hover:bg-slate-900"
                                                                                                    : "",
                                                                                            onClick:
                                                                                                header.column.getToggleSortingHandler(),
                                                                                        }}
                                                                                        key={
                                                                                            header.id
                                                                                        }
                                                                                        colSpan={
                                                                                            header.colSpan
                                                                                        }
                                                                                    >
                                                                                        {header.isPlaceholder ? null : (
                                                                                            <div>
                                                                                                {flexRender(
                                                                                                    header
                                                                                                        .column
                                                                                                        .columnDef
                                                                                                        .header,
                                                                                                    header.getContext()
                                                                                                )}
                                                                                                {{
                                                                                                    asc: " ⬆ ",
                                                                                                    desc: " ⬇",
                                                                                                }[
                                                                                                    header.column.getIsSorted() as string
                                                                                                ] ??
                                                                                                    null}
                                                                                            </div>
                                                                                        )}
                                                                                    </th>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </tr>
                                                                )
                                                            )}
                                                    </thead>
                                                    <tbody>
                                                        {debt_table
                                                            .getRowModel()
                                                            .rows.map(row => (
                                                                <tr
                                                                    key={row.id}
                                                                    className="hover:bg-slate-600"
                                                                >
                                                                    {row
                                                                        .getVisibleCells()
                                                                        .map(
                                                                            cell => {
                                                                                return (
                                                                                    <td
                                                                                        key={
                                                                                            cell.id
                                                                                        }
                                                                                        className=" border-b border-t "
                                                                                    >
                                                                                        {flexRender(
                                                                                            cell
                                                                                                .column
                                                                                                .columnDef
                                                                                                .cell,
                                                                                            cell.getContext()
                                                                                        )}
                                                                                    </td>
                                                                                );
                                                                            }
                                                                        )}
                                                                </tr>
                                                            ))}
                                                    </tbody>
                                                    <tfoot>
                                                        {debt_table
                                                            .getFooterGroups()
                                                            .map(
                                                                footerGroup => (
                                                                    <tr
                                                                        key={
                                                                            footerGroup.id
                                                                        }
                                                                        className="border "
                                                                    >
                                                                        {footerGroup.headers.map(
                                                                            header => {
                                                                                return (
                                                                                    <th
                                                                                        key={
                                                                                            header.id
                                                                                        }
                                                                                    >
                                                                                        {flexRender(
                                                                                            header
                                                                                                .column
                                                                                                .columnDef
                                                                                                .footer,
                                                                                            header.getContext()
                                                                                        )}
                                                                                    </th>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </tr>
                                                                )
                                                            )}
                                                    </tfoot>
                                                </table>
                                            </>
                                        )}
                                    </div>

                                    <div className="p-4 pt-0 mt-10 pb-0">
                                        <div> Tabela de Produção</div>
                                        <div>
                                            <DownloadTableExcel
                                                filename={`Tabela Produção ${
                                                    props.client
                                                } ${Date()}`}
                                                sheet="Tabela Produção"
                                                currentTableRef={
                                                    table_ref.current
                                                }
                                            >
                                                <button className="btn">
                                                    Exportar para excel
                                                </button>
                                            </DownloadTableExcel>
                                        </div>
                                    </div>
                                    <div className="p-4 pt-0 rounded-xl rounded-t-none max-h-[calc(75vh)] overflow-auto scroll ">
                                        {ClientData.data.table && (
                                            <>
                                                <table
                                                    className=" table-normal min-w-full bg-slate-700 text-center border-separate border overflow-scroll"
                                                    cellSpacing={0}
                                                    ref={table_ref}
                                                >
                                                    <thead className=" bg-slate-800 sticky top-0 z-10 ">
                                                        {table
                                                            .getHeaderGroups()
                                                            .map(
                                                                headerGroup => (
                                                                    <tr
                                                                        key={
                                                                            headerGroup.id
                                                                        }
                                                                    >
                                                                        {headerGroup.headers.map(
                                                                            header => {
                                                                                return (
                                                                                    <th
                                                                                        {...{
                                                                                            className:
                                                                                                header.column.getCanSort()
                                                                                                    ? "cursor-pointer select-none border-t border-b hover:bg-slate-900"
                                                                                                    : "",
                                                                                            onClick:
                                                                                                header.column.getToggleSortingHandler(),
                                                                                        }}
                                                                                        key={
                                                                                            header.id
                                                                                        }
                                                                                        colSpan={
                                                                                            header.colSpan
                                                                                        }
                                                                                    >
                                                                                        {header.isPlaceholder ? null : (
                                                                                            <div>
                                                                                                {flexRender(
                                                                                                    header
                                                                                                        .column
                                                                                                        .columnDef
                                                                                                        .header,
                                                                                                    header.getContext()
                                                                                                )}
                                                                                                {{
                                                                                                    asc: " ⬆ ",
                                                                                                    desc: " ⬇",
                                                                                                }[
                                                                                                    header.column.getIsSorted() as string
                                                                                                ] ??
                                                                                                    null}
                                                                                            </div>
                                                                                        )}
                                                                                    </th>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </tr>
                                                                )
                                                            )}
                                                    </thead>
                                                    <tbody>
                                                        {table
                                                            .getRowModel()
                                                            .rows.map(row => (
                                                                <tr
                                                                    key={row.id}
                                                                    className="hover:bg-slate-600"
                                                                >
                                                                    {row
                                                                        .getVisibleCells()
                                                                        .map(
                                                                            cell => {
                                                                                return (
                                                                                    <td
                                                                                        key={
                                                                                            cell.id
                                                                                        }
                                                                                        className=" border-b border-t "
                                                                                    >
                                                                                        {flexRender(
                                                                                            cell
                                                                                                .column
                                                                                                .columnDef
                                                                                                .cell,
                                                                                            cell.getContext()
                                                                                        )}
                                                                                    </td>
                                                                                );
                                                                            }
                                                                        )}
                                                                </tr>
                                                            ))}
                                                    </tbody>
                                                    <tfoot>
                                                        {table
                                                            .getFooterGroups()
                                                            .map(
                                                                footerGroup => (
                                                                    <tr
                                                                        key={
                                                                            footerGroup.id
                                                                        }
                                                                        className="border "
                                                                    >
                                                                        {footerGroup.headers.map(
                                                                            header => {
                                                                                return (
                                                                                    <th
                                                                                        key={
                                                                                            header.id
                                                                                        }
                                                                                    >
                                                                                        {flexRender(
                                                                                            header
                                                                                                .column
                                                                                                .columnDef
                                                                                                .footer,
                                                                                            header.getContext()
                                                                                        )}
                                                                                    </th>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </tr>
                                                                )
                                                            )}
                                                    </tfoot>
                                                </table>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );
}
