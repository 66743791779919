import React from "react";

export interface ILoadingProps {
    loading: any;
}

export function Loading(props: ILoadingProps) {
    return (
        <>
            <div className="modal modal-open">
                <div className="bg-zinc-700 flex justify-center items-center p-6 relative modal-box min-w-fit h-1/6 w-1/6 rounded-full">
                    {props.loading}
                </div>
            </div>
        </>
    );
}
