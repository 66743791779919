import { useContext, useEffect, useState } from "react";
import { authContext } from "./context/authContext";
import { Index } from "./pages";
import { Login } from "./pages/Login";

function App() {
    const { auth, validaToken } = useContext(authContext);
    const [localAuth] = useState(localStorage.getItem("auth"));

    useEffect(() => {
        if (localAuth) {
            validaToken(JSON.parse(localAuth), "Login");
        }
    }, []);

    return (
        <div className="flex w-full ">
            {auth.isAuth ? <Index /> : <Login />}
        </div>
    );
}

export default App;
