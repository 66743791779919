import React from "react";
import { FormLogin } from "../components/formLogin";

export interface ILoginProps {}
export function Login(props: ILoginProps) {
    return (
        <>
            <div className="flex-1 flex bg-cover bg-center h-screen bg-[url('../../public/image.jpg')] items-center justify-center">
                <FormLogin />
            </div>
        </>
    );
}
