import { ChartData } from "chart.js";
import { Data } from "./types";

export const createBarDataset = (data: Data | undefined) => {
    let dataSet: ChartData<"bar"> = {
        labels: ["HxC"],
        datasets: [],
    };
    if (data) {
        let colors = [
            "#75c043",
            "#093061",
            "#bcd631",
            "#008ed1",
            "#006830",
            "#205fad",
            "#ABFF73",
            "#91C1FB",
            "#45E329",
            "#2212E0",
            "#00B554",
            "#0C68E8",
            "#261C94",
            "#36B571",
            "#2266BA",
            "#69BF30",
            "#2212E0",
        ];
        const labelsOrdenadas = [...data.labels].sort();
        const valuesOrdenados = labelsOrdenadas.map(
            label => data.values[data.labels.indexOf(label)]
        );
        for (let index = 0; index < labelsOrdenadas.length; index++) {
            let rColor =
                colors[index >= colors.length ? index - colors.length : index];
            dataSet.datasets.push({
                label: formataTexto(labelsOrdenadas[index]),
                data: [valuesOrdenados[index]],
                barPercentage: 1,
                barThickness: 1000,
                backgroundColor: rColor,
            });
        }
        return dataSet;
    } else {
        return dataSet;
    }
};

export const formataTexto = (text: string) => {
    let size = text.split(" ").length;
    if (size < 2) {
        return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    } else {
        let newText =
            text.split(" ")[0].charAt(0).toUpperCase() +
            text.split(" ")[0].slice(1).toLowerCase();
        for (let index = 1; index < size; index++) {
            newText +=
                " " +
                text.split(" ")[index].charAt(0).toUpperCase() +
                text.split(" ")[index].slice(1).toLowerCase();
        }
        return newText;
    }
};

export function contador(value: any[]): {
    "Ha Plantio": number;
    "Endividamento Total": number;
    "Receita total": number;
    "Patrim\u00F4nio Total": number;
    Clientes: number;
} {
    let haPlantio = 0;
    let endividamentoTotal = 0;
    let receitaTotal = 0;
    let patrimonioTotal = 0;
    let clientes = 0;
    for (let i = 0; i < value.length; i++) {
        haPlantio += value[i].getValue("Ha Plantio");
        endividamentoTotal += value[i].getValue("Endividamento Total");
        receitaTotal += value[i].getValue("Receita total");
        patrimonioTotal += value[i].getValue("Patrimônio Total");
        clientes = i + 1;
    }
    const obj = {
        "Ha Plantio": haPlantio,
        "Endividamento Total": endividamentoTotal,
        "Receita total": receitaTotal,
        "Patrimônio Total": patrimonioTotal,
        Clientes: clientes,
    };
    return obj;
}
