import React from "react";
import { PlusTable } from "../components/suport/plusTable";
import { RobotFarmBoxTable } from "../components/suport/robotFarmBoxTable";
import { RobotSyngentaTable } from "../components/suport/robotSyngentaTable";
import { SaaErpTable } from "../components/suport/saaErpTable";

export interface IIndexProps {}

export function Suport(props: IIndexProps) {
    return (
        <div className="flex flex-1 flex-col bg-white w-screen ">
            <div className=" bg-zinc-900 min-w-max">
                <div className="p-4">
                    <PlusTable />
                </div>

                <div className="grid grid-flow-col gap-4 p-4">
                    <RobotFarmBoxTable />
                    <RobotSyngentaTable />
                    <SaaErpTable />
                </div>
            </div>
        </div>
    );
}
