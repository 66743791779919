import React, { lazy } from "react";
import { ClientResumeTable } from "../components/admin/clientResumeTable";
import { useQuery } from "react-query";
import { useAuth } from "../context/authContext";
import api from "../services/api";
import { TableBar } from "../components/admin/tableBar";
import { Loading } from "../components/loading";
import { PulseLoader } from "react-spinners";
import { CardList } from "../components/admin/cardList";
import { useStore } from "../context/storeContext";

export interface IIndexProps {}

export function Admin(props: IIndexProps) {
    const { auth } = useAuth();
    const { store } = useStore();

    const getTableBarData = async () => {
        return api.get("/culture_chart", {
            headers: {
                Authorization: "Bearer " + auth.user.token,
                "Content-Type": "application/json;charset=UTF-8",
            },
        });
    };

    const getCardData = async () => {
        return api.get("/cards", {
            headers: {
                Authorization: "Bearer " + auth.user.token,
                "Content-Type": "application/json;charset=UTF-8",
            },
        });
    };

    const getTableData = async () => {
        return api.get("/client_resume_table", {
            headers: {
                Authorization: "Bearer " + auth.user.token,
                "Content-Type": "application/json;charset=UTF-8",
            },
        });
    };

    const TableBarData = useQuery("TableBarData", getTableBarData);
    const CardData = useQuery("CardData", getCardData);
    const ClientResumeData = useQuery("ClientResumeData", getTableData);

    return (
        <div className="p-4 flex flex-1 flex-col overflow-hidden h-fit ">
            {ClientResumeData.isLoading &&
                !store.cards &&
                TableBarData.isLoading && (
                    <Loading loading={<PulseLoader color="#36d7b7" />} />
                )}

            <div className="h-fit bg-zinc-900 rounded-t-xl">
                {TableBarData.data && (
                    <TableBar
                        data={TableBarData.data.data}
                        title={"Total hectares x culturas"}
                        h="30px"
                    />
                )}
            </div>
            <div className="h-fit bg-zinc-900">
                {store.cards && <CardList key={"admin"} />}
            </div>
            <div className="h-fit bg-zinc-900 rounded-b-xl">
                {ClientResumeData.data && (
                    <ClientResumeTable
                        TableBarData={TableBarData}
                        CardData={CardData}
                    />
                )}
            </div>
        </div>
    );
}
