import { useContext, useEffect, useRef, useState } from "react";
import { useQueries, useQuery } from "react-query";
import "../../styles/table.css";
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
} from "@tanstack/react-table";
import { DTableData } from "../../types";
import { authContext } from "../../context/authContext";
import { fuzzyFilter } from "../admin/clientResumeTable";
import React from "react";

interface ITableProps {}

export function RobotSyngentaTable(props: ITableProps) {
    const { auth, validaToken } = useContext(authContext);
    const [localAuth] = useState(localStorage.getItem("auth"));
    const ClientResumeData = useQuery<any>("ClientResumeData");

    const [sorting, setSorting] = useState<SortingState>([]);
    const tableRef = useRef(null);

    const columnHelper = createColumnHelper<DTableData>();
    const columnHeaders = ["Cliente", "Envio"];
    const columns: any = [];
    const [data] = useState<any[]>([]);
    columnHeaders.forEach(element => {
        columns.push(
            columnHelper.accessor(element, {
                header: element,
                footer: "",
            })
        );
    });

    const table = useReactTable({
        data,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        state: {
            sorting,
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });
    return (
        <table
            className=" table-normal bg-slate-600 text-center border-separate border w-full"
            cellSpacing={0}
        >
            <thead className="bg-slate-800 sticky top-0 z-20 ">
                <th colSpan={columnHeaders.length}>Robot Syngenta</th>
                {table.getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map(header => {
                            return (
                                <th
                                    {...{
                                        className: header.column.getCanSort()
                                            ? "cursor-pointer select-none border-t border-b hover:bg-slate-900"
                                            : "",
                                        onClick:
                                            header.column.getToggleSortingHandler(),
                                    }}
                                    key={header.id}
                                    colSpan={header.colSpan}
                                >
                                    {header.isPlaceholder ? null : (
                                        <div>
                                            {flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                            {{
                                                asc: " ⬆ ",
                                                desc: " ⬇",
                                            }[
                                                header.column.getIsSorted() as string
                                            ] ?? null}
                                        </div>
                                    )}
                                </th>
                            );
                        })}
                    </tr>
                ))}
            </thead>
            <tbody>
                {table.getRowModel().rows.map(row => (
                    <tr key={row.id} className="hover:bg-slate-600">
                        {row.getVisibleCells().map(cell => {
                            return (
                                <td
                                    key={cell.id}
                                    className="cborder-b border-t"
                                >
                                    {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext()
                                    )}
                                </td>
                            );
                        })}
                    </tr>
                ))}
            </tbody>
            <tfoot>
                {table.getFooterGroups().map(footerGroup => (
                    <tr key={footerGroup.id} className="border ">
                        {footerGroup.headers.map(header => {
                            return (
                                <th key={header.id}>
                                    {flexRender(
                                        header.column.columnDef.footer,
                                        header.getContext()
                                    )}
                                </th>
                            );
                        })}
                    </tr>
                ))}
            </tfoot>
        </table>
    );
}
