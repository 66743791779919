import { useEffect, useRef, useState } from "react";
import { useQueries, useQuery } from "react-query";
import "../../styles/table.css";
import {
    ColumnFiltersState,
    createColumnHelper,
    FilterFn,
    flexRender,
    getCoreRowModel,
    getFacetedMinMaxValues,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
} from "@tanstack/react-table";
import { RankingInfo, rankItem } from "@tanstack/match-sorter-utils";
import { DTableData } from "../../types";
import { contador, formataTexto } from "../../helpers";
import ModalClient from "./modalClient";
import api from "../../services/api";
import { useAuth } from "../../context/authContext";
import { Loading } from "../loading";
import { PulseLoader } from "react-spinners";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { Filter } from "./filter";
import { useStore } from "../../context/storeContext";
import React from "react";

export interface ITableProps {
    TableBarData: any;
    CardData: any;
}

export const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value);
    addMeta({
        itemRank,
    });
    return itemRank.passed;
};

declare module "@tanstack/table-core" {
    interface FilterFns {
        fuzzy: FilterFn<unknown>;
    }
    interface FilterMeta {
        itemRank: RankingInfo;
    }
}

export function ClientResumeTable(props: ITableProps) {
    const { auth, validaToken } = useAuth();
    const store = useStore();
    const [localAuth] = useState(localStorage.getItem("auth"));
    const ClientResumeData = useQuery<any>("ClientResumeData");
    const clientList: string[] = ClientResumeData.data.data.map(
        (c: any) => c.Cliente
    );
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

    const getClient = async (clienteId: string) => {
        if (localAuth) validaToken(JSON.parse(localAuth), client);
        const response = await api.get(
            "/client_detail?client_id=" + clienteId,
            {
                headers: {
                    Authorization: "Bearer " + auth.user.token,
                    "Content-Type": "application/json;charset=UTF-8",
                },
            }
        );

        return response.data;
    };
    const clientListQueries = useQueries(
        clientList.map((c: string) => {
            return {
                queryKey: c,
                queryFn: () => getClient(c.match(/\d+/g)![0]),
                enabled: false,
            };
        })
    );

    const [sorting, setSorting] = useState<SortingState>([]);
    const [clientIndex, setClientIndex] = useState<number | undefined>(
        undefined
    );
    const [isOpen, setIsOpen] = useState(false);

    const tableRef = useRef(null);

    const [client, setClient] = useState<any>();

    useEffect(() => {
        if (client !== undefined) {
            if (clientIndex !== undefined) {
                clientListQueries[clientIndex]
                    .refetch({ active: true })
                    .then(() => setIsOpen(true));
            }
        }
    }, [client]);

    useEffect(() => {
        if (isOpen === false) {
            setClient(undefined);
            setClientIndex(undefined);
        }
    }, [isOpen]);

    const columnHelper = createColumnHelper<DTableData>();
    const columnHeaders = [
        "Cliente",
        "Estados",
        "Cidades",
        "Ha Plantio",
        "Patrimônio Total",
        "Receita total",
        "Endividamento Total",
        "Endividamento/Receita",
        "Endividamento/Patrimônio",
    ];
    const columns: any = [];
    const [data] = useState<any[]>([...ClientResumeData.data.data]);
    columnHeaders.forEach(element => {
        if (element === "Cliente") {
            columns.push(
                columnHelper.accessor(element, {
                    header: element,
                    id: element,
                    cell: props => {
                        return formataTexto(props.getValue().toString());
                    },
                })
            );
            return;
        }
        if (element === "Estados" || element === "Cidades") {
            columns.push(
                columnHelper.accessor(element, {
                    header: element,
                    enableColumnFilter: false,
                    cell: props => {
                        if (props.getValue().toString()[0] === ",")
                            return props.getValue().toString().slice(1);
                        return props.getValue().toString();
                    },
                })
            );
            return;
        }
        if (
            element === "Endividamento/Receita" ||
            element === "Endividamento/Patrimônio"
        ) {
            columns.push(
                columnHelper.accessor(element, {
                    header: element,
                    enableColumnFilter: false,
                    cell: props => {
                        return props.getValue() + "%";
                    },
                })
            );
            return;
        }
        if (
            element === "Patrimônio Total" ||
            element === "Receita total" ||
            element === "Endividamento Total"
        ) {
            columns.push(
                columnHelper.accessor(element, {
                    header: element,
                    enableColumnFilter: false,
                    cell: props => (
                        <div
                            className="tooltip"
                            data-tip={Number(
                                Number(props.getValue()).toFixed(1)
                            ).toLocaleString(undefined, {
                                minimumFractionDigits: 1,
                            })}
                        >
                            <label>
                                {Number(
                                    (
                                        Number(props.getValue()) / 1000000
                                    ).toFixed(1)
                                ).toLocaleString(undefined, {
                                    minimumFractionDigits: 1,
                                }) + "M"}
                            </label>
                        </div>
                    ),

                    footer: "",
                })
            );
            return;
        }
        if (element === "Ha Plantio") {
            columns.push(
                columnHelper.accessor(element, {
                    header: element,
                    enableColumnFilter: false,
                    cell: props =>
                        Number(
                            Number(props.getValue()).toFixed(1)
                        ).toLocaleString(undefined, {
                            minimumFractionDigits: 1,
                        }),
                    footer: "",
                })
            );
            return;
        }

        columns.push(
            columnHelper.accessor(element, {
                header: element,
                enableColumnFilter: false,
                footer: "",
            })
        );
    });

    const table = useReactTable({
        data,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        state: {
            sorting,
            columnFilters,
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        onColumnFiltersChange: setColumnFilters,
        getFilteredRowModel: getFilteredRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
    });
    useEffect(() => {
        store.dispatchStore({
            type: "set_store",
            payload: { cards: contador(table.getRowModel().rows) },
        });
    }, [table.getRowModel().rows]);

    return (
        <>
            {clientIndex !== undefined &&
                clientListQueries[clientIndex].isFetching && (
                    <Loading loading={<PulseLoader color="#36d7b7" />} />
                )}

            {}

            <div className="p-4 pb-0 flex justify-between pr-7 gap-4">
                <div className="flex gap-4">
                    <Filter
                        column={table.getColumn("Cliente")}
                        table={table}
                        title="CLIENTES"
                        key={"CLIENTES"}
                    />
                    <Filter
                        column={table.getColumn("Estados")}
                        table={table}
                        title="ESTADOS"
                        key={"ESTADOS"}
                    />
                    <Filter
                        column={table.getColumn("Cidades")}
                        table={table}
                        title="CIDADES"
                        key={"CIDADES"}
                    />
                </div>

                <DownloadTableExcel
                    filename={`Tabela Clientes ${Date()}`}
                    sheet="Tabela Clientes"
                    currentTableRef={tableRef.current}
                >
                    <button className="btn h-full">Exportar para excel</button>
                </DownloadTableExcel>
            </div>

            <div className="p-4 pt-0  rounded-xl rounded-t-none h-[calc(90vh)] overflow-scroll ">
                <table
                    ref={tableRef}
                    className=" table-normal bg-slate-700 text-center border-separate border min-w-full"
                    cellSpacing={0}
                >
                    <thead className=" bg-slate-800 sticky top-0 z-20 ">
                        {table.getHeaderGroups().map(headerGroup => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map(header => {
                                    return (
                                        <th
                                            key={header.id}
                                            colSpan={header.colSpan}
                                        >
                                            {header.isPlaceholder ? null : (
                                                <div
                                                    {...{
                                                        className:
                                                            header.column.getCanSort()
                                                                ? "cursor-pointer select-none hover:underline "
                                                                : "",
                                                        onClick:
                                                            header.column.getToggleSortingHandler(),
                                                    }}
                                                >
                                                    {flexRender(
                                                        header.column.columnDef
                                                            .header,
                                                        header.getContext()
                                                    )}
                                                    {{
                                                        asc: " ⬆ ",
                                                        desc: " ⬇",
                                                    }[
                                                        header.column.getIsSorted() as string
                                                    ] ?? null}
                                                </div>
                                            )}
                                        </th>
                                    );
                                })}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {table.getRowModel().rows.map(row => (
                            <tr key={row.id} className="hover:bg-slate-600">
                                {row.getVisibleCells().map(cell => {
                                    return (
                                        <td
                                            key={cell.id}
                                            className="cursor-pointer border-b border-t  "
                                            onClick={() => {
                                                setClientIndex(
                                                    Number(
                                                        cell.id.split("_")[0]
                                                    )
                                                );
                                                setClient(
                                                    data[
                                                        Number(
                                                            cell.id.split(
                                                                "_"
                                                            )[0]
                                                        )
                                                    ].Cliente
                                                );

                                                const page =
                                                    document.getElementById(
                                                        "page"
                                                    );
                                                if (page)
                                                    page.style.overflow =
                                                        "hidden";
                                            }}
                                        >
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )}
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        {table.getFooterGroups().map(footerGroup => (
                            <tr key={footerGroup.id} className="border ">
                                {footerGroup.headers.map(header => {
                                    return (
                                        <th key={header.id}>
                                            {flexRender(
                                                header.column.columnDef.footer,
                                                header.getContext()
                                            )}
                                        </th>
                                    );
                                })}
                            </tr>
                        ))}
                    </tfoot>
                </table>
                {clientIndex !== undefined &&
                    isOpen &&
                    clientListQueries[clientIndex].isFetched && (
                        <ModalClient
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            client={client}
                        />
                    )}
            </div>
        </>
    );
}
