import {
    Chart,
    ChartData,
    ChartOptions,
    LegendItem,
    registerables,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartjsPluginStacked100 from "chartjs-plugin-stacked100";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useEffect, useState } from "react";
import { createBarDataset } from "../../helpers";
import { Data } from "../../types";
import React from "react";

interface Ibar {
    data: Data;
    title: string;
    h: string;
}

export function TableBar(props: Ibar) {
    const [data] = useState<ChartData<"bar">>(createBarDataset(props.data));
    const [value, setValue] = useState<any>();
    let hovering = false;
    let tooltip: any = document.getElementById("tooltip");
    let tooltips = Chart.instances[0]?.data.datasets.map(
        d =>
            Number(Number(d.data).toFixed(1)).toLocaleString(undefined, {
                minimumFractionDigits: 1,
            }) + " ha"
    );
    const [mousePos, setMousePos] = useState({ x: 0, y: 0 });
    useEffect(() => {
        const handleMouseMove = (event: any) => {
            setMousePos({ x: event.clientX, y: event.clientY + 5 });
        };

        window.addEventListener("mousemove", handleMouseMove);

        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
        };
    }, []);

    const ChartOptions: ChartOptions<"bar"> = {
        responsive: true,
        scales: {
            y: {
                stacked: true,
                ticks: {
                    display: false,
                },
            },
            x: {
                stacked: true,
                display: false,
                max: 100,
            },
        },
        indexAxis: "y",
        plugins: {
            stacked100: {
                precision: 1,
                enable: true,
            },
            legend: {
                display: true,
                position: "bottom",

                onHover: function (event: any, legendItem) {
                    if (hovering) {
                        return;
                    }
                    hovering = true;

                    //@ts-ignore
                    tooltip.innerHTML = tooltips[legendItem.datasetIndex];
                    tooltip.className =
                        "absolute z-20 bg-slate-700 rounded-lg p-1 text-sm";

                    tooltip.style.left = event.x + 40 + "px";
                    tooltip.style.top = mousePos.y + "px";
                },
                onLeave: function () {
                    hovering = false;
                    tooltip.innerHTML = "";
                    tooltip.className = "";
                },
                labels: {
                    generateLabels(chart: any) {
                        let data = chart.data.datasets;
                        let label: LegendItem[] = [];
                        for (let index = 0; index < data.length; index++) {
                            label.push({
                                text: data[index].label,
                                index: index,
                                datasetIndex: index,
                                fillStyle: data[index].backgroundColor,
                                borderRadius: 3,
                                hidden:
                                    chart.getDatasetMeta(index).hidden === true
                                        ? true
                                        : false,
                                lineWidth: 0,
                            });
                        }

                        return label;
                    },
                    color: "white",

                    boxWidth: 20,
                    font: {
                        size: 16,
                    },
                },
            },
            tooltip: {
                callbacks: {
                    title(tooltipItems: any) {
                        return (
                            "Total de hectares de " +
                            tooltipItems[0].dataset.label
                        );
                    },

                    label(tooltipItem) {
                        //
                        return (
                            Number(
                                Number(tooltipItem.dataset.data[0]).toFixed(1)
                            ).toLocaleString(undefined, {
                                minimumFractionDigits: 1,
                            }) + " ha"
                        );
                    },
                },
                xAlign: "center",
            },
            datalabels: {
                color: "white",
                font: {
                    weight: "bold",
                    size: 16,
                },
                align: "center",
                anchor: "center",

                formatter(value, context: any) {
                    let soma = 0;
                    context.chart._metasets.forEach((c: any) => {
                        if (c.hidden !== true) {
                            soma += data.datasets.filter(
                                (d, index) => c.index === index
                            )[0].data[0];
                        }
                    });
                    setValue(soma);

                    return (
                        context.chart.data.calculatedData[
                            context.datasetIndex
                        ] + "%"
                    );
                },
            },
        },
    };
    Chart.register(...registerables, ChartjsPluginStacked100, ChartDataLabels);
    useEffect(() => {
        setTimeout(() => {
            //Por algum motivo no servidor o chart é 0
            Chart.instances[0]?.data.datasets.forEach((dataSet, i) => {
                if (
                    Chart.instances[0].getDatasetMeta(i).label !== "Soja" &&
                    Chart.instances[0].getDatasetMeta(i).label !== "Milho" &&
                    Chart.instances[0].getDatasetMeta(i).label !== "Algodao"
                ) {
                    Chart.instances[0].getDatasetMeta(i).hidden = true;
                }
            });
            // Chart.instances[1]?.data.datasets.forEach((dataSet, i) => {
            //     if (
            //         Chart.instances[1].getDatasetMeta(i).label !== "Soja" &&
            //         Chart.instances[1].getDatasetMeta(i).label !== "Milho" &&
            //         Chart.instances[1].getDatasetMeta(i).label !== "Algodao"
            //     ) {
            //         Chart.instances[1].getDatasetMeta(i).hidden = true;
            //     }
            // });
        }, 1000);
    }, [data]);
    return (
        <div className=" rounded-xl rounded-b-none p-2 ">
            <label className="">
                {props.title} (
                {value
                    ? Number(value.toFixed(1)).toLocaleString(undefined, {
                          minimumFractionDigits: 1,
                      })
                    : "00.00"}{" "}
                ha)
            </label>
            <div id="tooltip"></div>
            <div className="relative w-full  ">
                <Bar height={props.h} data={data} options={ChartOptions} />
            </div>
        </div>
    );
}
