import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./styles/global.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { AuthProvider } from "./context/authContext";
// import { ReactQueryDevtools } from "react-query/devtools";
import { StoreProvider } from "./context/storeContext";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <AuthProvider>
                <StoreProvider>
                    <App />
                    {/* <ReactQueryDevtools /> */}
                </StoreProvider>
            </AuthProvider>
        </QueryClientProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
