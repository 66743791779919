import React from "react";
import { createContext, useContext, useReducer } from "react";
import { storeReducer } from "../reducers/storeReducer";

export interface IDispatch {
    type: string;
    payload: IStore;
}
interface IStore {
    cards?: {
        "Ha Plantio": number;
        "Endividamento Total": number;
        "Receita total": number;
        "Patrim\u00F4nio Total": number;
        Clientes: number;
    };
}

const defaultStore = {
    store: {} as IStore,
    dispatchStore: (value: IDispatch) => {},
};

export const storeContext = createContext(defaultStore);

export function StoreProvider(props: { children: React.ReactNode }) {
    const [store, dispatchStore] = useReducer(storeReducer, {});

    return (
        <storeContext.Provider
            value={{ store, dispatchStore }}
            children={props.children}
        />
    );
}

export function useStore() {
    return useContext(storeContext);
}
