import { Column, Table } from "@tanstack/react-table";
import React from "react";
import { useEffect, useMemo, useState } from "react";

export function Filter({
    column,
    table,
    title,
}: {
    column: Column<any, unknown>;
    table: Table<any>;
    title: string;
}) {
    const firstValue = table
        .getPreFilteredRowModel()
        .flatRows[0]?.getValue(column.id);
    const [value, setValue] = useState([]);
    const sv = new Set();
    const columnFilterValue = column.getFilterValue();
    const sortedUniqueValues = useMemo(
        () =>
            typeof firstValue === "number"
                ? []
                : Array.from(column.getFacetedUniqueValues().keys()).sort(),
        [column.getFacetedUniqueValues()]
    );
    useEffect(() => {
        sortedUniqueValues.forEach(s => {
            s.split(",").forEach((v: any) => sv.add(v));
        });
        let arr: any = [];
        sv.forEach(v => {
            if (v !== "") arr.push(v);
        });
        setValue(arr);
    }, [sortedUniqueValues]);

    return typeof firstValue === "number" ? (
        <div>
            <input
                autoComplete="false"
                type="number"
                min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
                max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
                value={(columnFilterValue as [number, number])?.[0] ?? ""}
                onChange={value =>
                    column.setFilterValue((old: [number, number]) => [
                        value,
                        old?.[1],
                    ])
                }
                placeholder={`Min ${
                    column.getFacetedMinMaxValues()?.[0]
                        ? `(${column.getFacetedMinMaxValues()?.[0]})`
                        : ""
                }`}
                className="w-24 border shadow rounded"
            />
            <input
                type="number"
                min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
                max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
                value={(columnFilterValue as [number, number])?.[1] ?? ""}
                onChange={value =>
                    column.setFilterValue((old: [number, number]) => [
                        old?.[0],
                        value,
                    ])
                }
                placeholder={`Max ${
                    column.getFacetedMinMaxValues()?.[1]
                        ? `(${column.getFacetedMinMaxValues()?.[1]})`
                        : ""
                }`}
                className="w-24 border shadow rounded"
            />

            <div className="h-1" />
        </div>
    ) : (
        <div className="flex">
            <datalist id={column.id + "list"}>
                {value.sort().map((value: any) => (
                    <option value={value} key={value} />
                ))}
            </datalist>
            <input
                type="text"
                autoComplete="off"
                value={(columnFilterValue ?? "") as string}
                onChange={value => {
                    column.setFilterValue(value.target.value);
                }}
                placeholder={`PROCURAR ${title}... (${
                    column.getFacetedUniqueValues().size
                })`}
                className="w-full font-semibold h-full p-2 "
                list={column.id + "list"}
            />
            <button className="btn" onClick={() => column.setFilterValue("")}>
                X
            </button>
        </div>
    );
}
