import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { authContext } from "../context/authContext";
import { FormValues } from "../types";

export interface IFormLoginProps {}

export function FormLogin(props: IFormLoginProps) {
    const { register, handleSubmit } = useForm<FormValues>();
    const { login } = React.useContext(authContext);

    const onSubmit: SubmitHandler<FormValues> = data => {
        login(data);
    };

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="card min-w-[350px] p-10 rounded-3xl w-fit bg-white backdrop-blur-[12px] bg-opacity-75 backdrop-saturate-200 "
        >
            <input
                {...register("email")}
                type="text"
                placeholder="Login"
                autoComplete="off"
                className="input input-bordered w-full max-w-xs mb-2"
            />
            <input
                {...register("pass")}
                type="password"
                placeholder="Senha"
                autoComplete="off"
                className="input input-bordered w-full max-w-xs"
            />
            <button type="submit" className="btn mt-4 ">
                Entrar
            </button>
        </form>
    );
}
