import React, { useState } from "react";
import { Admin } from "./admin";
import { Suport } from "./suport";

export interface IIndexProps {}
const ViewTypes = { admin: "admin", suport: "suport" };

export function Index(props: IIndexProps) {
    const [view, setView] = useState<String>(ViewTypes.admin);
    const [admin, setAdmin] = useState(false);

    return (
        <div>
            <div className="drawer drawer-side">
                <input
                    id="my-drawer-4"
                    type="checkbox"
                    className="drawer-toggle"
                />
                <div className="drawer-content" id="page">
                    {admin && (
                        <label
                            htmlFor="my-drawer-4"
                            className="drawer-button btn btn-block"
                        >
                            MODO
                        </label>
                    )}

                    {view === ViewTypes.admin && <Admin />}
                    {view === ViewTypes.suport && <Suport />}
                </div>
                <div className="drawer-side">
                    <label
                        htmlFor="my-drawer-4"
                        className="drawer-overlay"
                    ></label>
                    <ul className="menu p-4 w-80 bg-zinc-900 text-base-content">
                        <div className="btn-group btn-group-vertical">
                            <input
                                placeholder="Admin"
                                type="radio"
                                name="options"
                                data-title="Admin"
                                className="btn"
                                // checked={view === ViewTypes.admin}
                                onClick={() => setView(ViewTypes.admin)}
                            />
                            <input
                                placeholder="Suporte"
                                type="radio"
                                name="options"
                                data-title="Suporte"
                                className="btn"
                                // checked={view === ViewTypes.suport}
                                onClick={() => setView(ViewTypes.suport)}
                            />
                        </div>
                    </ul>
                </div>
            </div>
        </div>
    );
}
