import React from "react";
import axios from "axios";
import { createContext, useContext, useReducer, useState } from "react";
import { authReducer } from "../reducers/authReducer";
import api from "../services/api";
import { Auth, FormValues } from "../types";

export interface IDispatch {
    type: string;
    payload: any;
}
const defaultAuth = {
    auth: {} as Auth,
    login: function (data: FormValues) {},
    dispatchAuth: (value: IDispatch) => {},
    validaToken: (authObj: Auth, location: string) => {},
    cards: {} as any,
    setCards: {} as any,
};

export const authContext = createContext(defaultAuth);

export function AuthProvider(props: { children: React.ReactNode }) {
    const [cards, setCards] = useState({});
    const [auth, dispatchAuth] = useReducer(authReducer, {
        isAuth: false,
        user: {},
    });

    const login = async (data: FormValues) => {
        await axios
            .post("https://api2.saasoftware.com.br/saadash/login", data, {
                headers: {
                    "Content-Type": "text/plain",
                },
            })
            .then(res => {
                if (res.data.erro) {
                    alert(res.data.erro);
                } else {
                    dispatchAuth({
                        type: "set_auth",
                        payload: { isAuth: true, user: { ...res.data } },
                    });
                    localStorage.setItem(
                        "auth",
                        JSON.stringify({ isAuth: true, user: { ...res.data } })
                    );
                }
            })
            .catch(err => {
                window.location.reload();
            });
    };

    const validaToken = async (auth: Auth, location: string) => {
        api.get("/cards", {
            headers: {
                Authorization: "Bearer " + auth.user.token,
                "Content-Type": "application/json;charset=UTF-8",
            },
        })
            .then(() =>
                dispatchAuth({
                    type: "set_auth",
                    payload: auth,
                })
            )
            .catch(() => {
                dispatchAuth({
                    type: "set_auth",
                    payload: { isAuth: false, user: {} },
                });
                localStorage.removeItem("auth");
                window.location.reload();
            });
    };

    return (
        <authContext.Provider
            value={{ auth, login, dispatchAuth, validaToken, cards, setCards }}
            children={props.children}
        />
    );
}

export function useAuth() {
    return useContext(authContext);
}
