import React from "react";
import { useStore } from "../../context/storeContext";
import { CardTitle } from "../../types";

export function CardList() {
    const { store } = useStore();
    const cardTitle: CardTitle = [
        "Ha Plantio",
        "Endividamento Total",
        "Receita total",
        "Patrim\u00F4nio Total",
        "Clientes",
    ];
    return (
        <>
            {store.cards && (
                <div className="grid grid-flow-col p-4 gap-4 overflow-x-scroll">
                    <div className="card whitespace-nowrap w-full bg-slate-800 shadow-xl border text-white  ">
                        <div className="card-body text-center ">
                            <h2 className="text-lg whitespace-nowrap">
                                {store.cards.Clientes === 1
                                    ? "Clientes"
                                    : "Cliente"}
                            </h2>
                            <label className="text-4xl font-extrabold">
                                {store.cards.Clientes}
                            </label>
                        </div>
                    </div>
                    {cardTitle.map(title => {
                        if (title !== "Clientes" && store.cards !== undefined) {
                            return (
                                <div
                                    className="card whitespace-nowrap w-full bg-slate-800 shadow-xl border text-white"
                                    key={title}
                                >
                                    <div className="card-body text-center ">
                                        <h2 className="text-lg whitespace-nowrap">
                                            {title}
                                        </h2>
                                        <label className="text-4xl font-extrabold">
                                            {title === "Ha Plantio" &&
                                                Number(
                                                    store.cards[title].toFixed(
                                                        1
                                                    )
                                                ).toLocaleString(undefined, {
                                                    minimumFractionDigits: 1,
                                                }) + " ha"}
                                            {title !== "Ha Plantio" &&
                                                "R$ " +
                                                    Number(
                                                        (
                                                            store.cards[title] /
                                                            1000000000
                                                        ).toFixed(1)
                                                    ).toLocaleString(
                                                        undefined,
                                                        {
                                                            minimumFractionDigits: 1,
                                                        }
                                                    ) +
                                                    " B"}
                                        </label>
                                    </div>
                                </div>
                            );
                        }
                        return <div key={title} className="hidden"></div>;
                    })}
                </div>
            )}
        </>
    );
}
